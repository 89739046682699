div.catalog {
  margin-bottom: 80px;
  padding-bottom: 50px;
}

div.catalog img {
  cursor: pointer;
}

div.catalog input.search {
  border-radius: 10px;
  background: #404040;
  border: none;
  outline: none;
  color: #fff;
  margin-top: 0em;
  margin-top: 2em;
  font-size: 1.5em;
  margin-bottom: 2.2em;
  padding: 5px;
  text-align: center;
  width: 640px;
  max-width: 95vw;
  filter: drop-shadow(0px 5px 3px #0f0f0f);
}

div.catalog div.catalogOverlay {
  align-content: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

div.catalog span.noResults {
  color: #b3b3b3;
}

@media only screen and (max-width: 768px) {
  div.catalog div.catalogOverlay {
  }

  div.catalog input.search {
    margin-top: 1em;
    width: 80vw;
    font-size: 1.7em;
    margin-bottom: 1em;
  }
}
