div.navigation {
  text-align: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.3em;
  margin-right: 1em;
  overflow: hidden;
  float: right;
}

div.navigation button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 5px;
  background: none;
  margin-right: -1.3em;
  color: #aaa;
  font-size: 1.5em;
  width: 100px;
  border-radius: 50px;
}


div.navigation button:hover {
  cursor: pointer;
  color: #fff;
}

div.navigation button.active {
  cursor: pointer;
  color: #32cd32;
}

div.navigation button.active:hover {
  cursor: auto;
  color: #32cd32;
}

div.navigation button.playing {
  cursor: pointer;
  color: #FF5050;
}

div.navigation button.playing:hover {
  cursor: pointer;
  color: #FF5050;
}

div.navigation span.buttonText {
  font-size: 0.5em;
  width: 100px;
  margin-left: -0.5em;
  margin-right: 1em;
  float: left;
  text-transform: uppercase;
}

@media only screen and (max-width: 1070px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1em;
    overflow: hidden;
    margin-right: 5px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: 0.7em;
  }

  div.navigation span.buttonText {
    display: none;
  }

}

@media only screen and (max-width: 768px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1em;
    overflow: hidden;
    margin-right: 5px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
  }

  div.navigation span.buttonText {
    display: none;
  }

}

@media only screen and (max-width: 600px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1em;
    overflow: hidden;
    margin-right: 5px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
  }

  div.navigation span.buttonText {
    display: none;
  }

}

@media only screen and (max-width: 550px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1em;
    overflow: hidden;
    margin-right: 15px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: -0.3em;
  }

  div.navigation span.buttonText {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1em;
    overflow: hidden;
    margin-right: 15px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: -0.5em;
  }

  div.navigation span.buttonText {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 1em;
    overflow: hidden;
    margin-right: 15px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: -0.7em;
  }

  div.navigation span.buttonText {
    display: none;
  }
}


@media only screen and (max-width: 400px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0.9em;
    overflow: hidden;
    margin-right: 15px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: -0.7em;
  }

  div.navigation span.buttonText {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0.8em;
    overflow: hidden;
    margin-right: 15px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: -0.7em;
  }

  div.navigation span.buttonText {
    display: none;
  }
}

@media only screen and (max-width: 300px) {
  div.navigation {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    margin-right: 15px;
  }
  div.navigation button {
    font-size: 1.4em;
    width: 50px;
    margin-right: -0.7em;
  }

  div.navigation span.buttonText {
    display: none;
  }
}


@media only screen and (min-width: 960px) {

  div.navigation button:hover {
    color: #fff;
  }
}
