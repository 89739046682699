.logo-container {
  position: relative;
  width: 100%;
  max-width: 950px; 
  margin: 0 auto; 
}

.logo {
  position: relative;
  z-index: 1;
}

.logo img.logo-image {
  width: 100%;
  filter: drop-shadow(0px 5px 10px #000000);
}

.notification {
  font-weight: bold;
  position: absolute;
  bottom: 7px;
  right: 7px; 
  color: yellowgreen;
  border: 1px solid #000;
  font-size: 13px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7); 
  padding: 5px;
  max-width: 450px;
  border-radius: 5px;
}

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .logo-container {
    width: 100%; 
  }

  .notification {
    position: static;
    margin-top: 5px; 
    max-width: 100%;
    margin-right: 4px;
    margin-left: 4px;
  }
}
