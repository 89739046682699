div.musicPlayer {
  background: #000;
  width: 100vw;
  overflow: hidden;
  bottom: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
}

div.musicPlayer div.playerContent {
  display: inline-block;
  height: 90px;
  padding-top: 0.3em;
  line-height: 1em;
  display: flex;
  justify-content: space-between;
}

div.musicPlayer button {
  float: left;
  font-size: 1.7em;
  border-radius: 100%;
  margin-top: 15px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: #32cd32;
  cursor: pointer;
  font-weight: bold;
  z-index: 999;
  background: none;
  border: none;
  width: 1.7em;
  height: 1.7em;
  padding-left: 0.35em;
  padding-top: 0.11em;
}

div.musicPlayer div.metadata {
  color: #ddd;
  text-align: left;
  font-size: 1.2em;
  margin-top: -0.1em;
  text-transform: uppercase;
  font-weight: bold;
  height: 4em;
  line-height: 1.2em;
  max-width: 500px;
  display: flex;
  align-items: center;
}

div.musicPlayer button.pause {
  background: none;
  color: #FF5050;
}

@media only screen and (max-width: 1050px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    text-align: left;
    padding-top: 0em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.5em;
    margin-top: 0.5em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 60px;
    font-size: 1em;
    width: 390px;
    margin-top: -0em;
    margin-left: 5px;
  }
}


div.musicPlayer button.pause {
  padding-right: 1em;
  line-height: 1.7em;
  padding-bottom: 0.2em;
}

@media only screen and (max-width: 600px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.9em;
    width: 300px;
    margin-right: 100px;
    margin-top: -0.3em;
  }
}


@media only screen and (max-width: 550px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.9em;
    width: 265px;
    margin-right: 100px;
    margin-top: -0.3em;
  }
}

@media only screen and (max-width: 500px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.9em;
    width: 250px;
    margin-right: 100px;
    margin-top: -0.3em;
  }
}

@media only screen and (max-width: 450px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.8em;
    width: 200px;
    margin-right: 100px;
    margin-top: 0em;
  }
}

@media only screen and (max-width: 400px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.7em;
    width: 180px;
    margin-right: 100px;
    margin-top: 0.1em;
  }
}

@media only screen and (max-width: 350px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 70px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.67em;
    width: 140px;
    margin-right: 100px;
    margin-top: 0.3em;
  }
}

@media only screen and (max-width: 300px) {
  div.musicPlayer {
    padding-bottom: 0em;
    height: 80px;
    padding-top: 0.3em;
  }

  div.musicPlayer button {
    position: absolute;
    left: 0;
    margin-left: 0.5em;
    font-size: 1.3em;
    margin-top: 0.4em;
    display: block;
    z-index: 999;
  }

  div.musicPlayer div.metadata {
    position: absolute;
    left: 50px;
    font-size: 0.67em;
    width: 105px;
    word-break: break-all;
    margin-right: 100px;
    margin-top: 1em;
  }
}
