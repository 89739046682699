html, body {
    max-width: 100%;
    overflow-x: hidden;
    background: #1a1a1a;
}

div.bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: -1px;
}

.App {
  text-align: center;
  overflow: hidden;
  overflow-x: hidden;
  width: 100vw;
}
