div.release {
}

div.release div.menuEntry:hover span.metadata {
  color: #fff;
}

div.release span.metadata {
  color: #32cd32;
  font-size: 1em;
  display: block;
  margin-top: 0em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  min-height: 4em;
}

div.release div.menuEntry {
  border-radius: 5px;
  max-width: 280px;
  margin: 16px;
  text-align: center;
  padding: 10px;
  background: #111;
  filter: drop-shadow(0px 5px 5px #000000);
}

div.release div.menuEntry:hover {
  cursor: pointer;
  background: #666;
  color: #fff;
}

div.release button {
  cursor: pointer;
}

div.release div.albumContainer {
  width: 1024px;
  padding-top: 1.3em;
  margin-left: auto;
  margin-right: auto;
}

div.release img.coverThumb {
  width: 100%;
  object-fit: cover;
}

div.release img.coverMid {
  float: left;
  width: 100%;
  max-width: 450px;
  object-fit: cover;
  margin-top: 0em;
  filter: drop-shadow(0px 7px 7px #000);
}

div.release div.coverOverlay {
  background: #000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

div.release img.coverBig {
  max-height: 80vh;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #eee;
}

div.release div.headingContainer {
  display: flex;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
}

div.release div.headingContainer h2 {
  font-size: 1.5em;
  flex-grow: 0;
  color: #32cd32;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 1em;
  margin: auto;
  margin-top: 1em;
  text-shadow: -1px 2px 10px rgba(34,139,34,0.6);
}

div.release div.headingContainer button {
  border: none;
  background: none;
  color: forestgreen;
  font-size: 1.5em;
}

div.release div.headingContainer button.prevRelease:hover {
  color: #fff;
}

div.release div.headingContainer button.nextRelease:hover {
  color: #fff;
}

div.release div.headingContainer  button.closeOverlay:hover {
  color: #fff;
}

div.release div.headingContainer button.closeOverlay {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #FF5050;
  font-size: 1.7em;
}

div.release div.albumContainer  button.closeOverlay::before {
  font-size: 1.5em;
}

div.release div.headingContainer div.controls {
  position: absolute;
  top: 15px;
  left: 15px;
}

@media only screen and (max-width: 1024px) {
  div.release {
    overflow-x: hidden;
  }

  div.release div.albumOverlay {
  }

  div.release div.albumContainer {
    width: 100vw;
  }

  div.release img.coverMid {
    float: none;
    max-width: 500px;
    padding-bottom: 0.3em;
  }

  div.release img.coverBig {
    max-width: 95vw;
  }


  div.release div.headingContainer div.controls {
    top: 10px;
    left: 5px;
  }

  div.release div.headingContainer button.closeOverlay {
    top: 10px;
    right: 5px;
  }

  div.release div.headingContainer {
  }

  div.release div.headingContainer h2 {
    max-width: 600px;
    font-size: 1.7em;
    text-align: center;
    margin-top: 0.8em;
    margin-left: auto;
    margin-right: auto;
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media only screen and (max-width: 500px) {
  div.release div.coverOverlay {
    display: none;
  }

  div.release img.coverBig {
    width: 0;
    height: 0;
    display: none;
  }

  div.release div.albumContainer {
    padding-top: 0em;
  }

  div.release div.headingContainer {
    background: #000;
  }

  div.release div.headingContainer h2 {
    clear: both;
    width: 100vw;
    font-size: 1em;
    margin-top: 0.5em;
  }

}
