div.track {
  margin: 5px;
  display: grid;
  grid-template-columns: 20px auto auto 1fr auto;
  grid-template-rows: auto;
  align-items: center;
  grid-column-gap: 5px;
  margin-top: 1em;
  max-width: 624px;
  cursor: pointer;
}

div.track:hover {
  border-radius: 5px;
  background: #333;
}

div.track span.track-number,
div.track span.track-artist,
div.track span.track-title,
div.track span.track-length {
  display: flex;
  align-items: center;
}

div.track span.track-number,
div.track span.track-artist {
  color: #777;
  text-align: left;
  vertical-align: top;
  justify-content: flex-start;
  align-self: flex-start;
}

div.track span.track-number {
  text-align: left;
  vertical-align: top;
  justify-content: flex-start;
  align-self: flex-start;
}

div.track span.track-artist {
  text-align: left;
  vertical-align: top;
  justify-content: flex-start;
  align-self: flex-start;
  color: #888;
}

div.track span.track-title {
  text-align: left;
  align-self: flex-start;
  color: #eee;
}

div.track span.track-length {
  text-align: left;
  vertical-align: top;
  justify-content: flex-start;
  align-self: flex-start;
  color: #777;
}

div.track span.track-artist:after {
  content: '-';
  margin-left: 0.3em;
}

div.track button {
  clear: both;
  padding: 0.3em;
  font-size: 0.7em;
  border-radius: 20px;
  color: #111;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.7em;
  display: flex;
  text-align: left;
  vertical-align: top;
  justify-content: flex-start;
  align-self: flex-start;
  margin-top: 0.3em;
}

div.track button.play,
div.track button.pause {
  background: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(136, 136, 136, 1) 100%);
  border: none;
  padding-left: 0.5em;
}

div.selectedTrack span {
  color: #32cd32 !important;
  height: auto;
}

div.playing {
  border-radius: 5px;
  background: #333;
  margin-left: 470px;
}

div.notPlaying {
  margin-left: 470px;
}

@media only screen and (max-width: 1024px) {
  div.track {
    display: grid;
    grid-template-columns: 20px auto auto 1fr auto;
    grid-template-rows: auto;
    align-items: center;
    grid-column-gap: 5px;
    margin-top: 1em;
    clear: both;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
  }

  div.track span.track-title {
    line-height: 1.3em;
    margin-top: 0.2em;
  }

  div.notPlaying {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    clear: both;
  }

  div.playing {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    clear: both;
  }
}

@media only screen and (max-width: 500px) {
  div.track {
    width: 100vw;
    max-width: 100vw;
  }

  div.track span.track-length {
    display: none;
  }

  div.notPlaying {
    margin-left: 0px;
    width: 100vw;
    clear: both;
  }

  div.playing {
    width: 100vw;
    margin-left: 0px;
  }

}
