div.shoutbox {
  margin-top: -0.5em;
  margin-bottom: 80px;
  clear: both;
  max-width: 945px;
  margin-left: auto;
  margin-right: auto;
}

div.shoutbox input {
  border-radius: 10px;
  background-color: #404040;
  border: none;
  filter: drop-shadow(0px 5px 3px #0f0f0f);
}

 div.shoutbox div.inputs {
   font-size: 1.5em;
   align-content: center;
   justify-content: space-between;
   display: flex;
   flex-wrap: wrap;
   margin-top: 1em;
}

div.shoutbox div.inputs input.nick {
  border: none;
  color: #fff;
  width: 20%;
  padding-left: 0.5em;
}

div.shoutbox div.inputs  input.message {
  border: none;
  color: #fff;
  width: 68%;
  padding-left: 0.5em;
}

div.shoutbox div.inputs  button {
  border-radius: 10px;
  min-width: 10%;
  font-weight: bold;
  color: #fff;
  border: none;
  background: forestgreen;
  border: none;
  outline: none;
  font-size: 0.75em;
  cursor: pointer;
}

div.shoutbox div.inputs button:hover {
  cursor: pointer;
  background: #32cd32;
}

div.shoutbox div.shouts {
  background: #111;
  font-size: 1.3em;
  margin-top: 1em;
  text-align: left;
  padding: 0.2em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  max-width: 950px;
  border-radius: 10px;
  font-family: "Courier New";
  filter: drop-shadow(0px 5px 5px #000000);
}

div.shoutbox div.shouts span.nick {
  color: #FF5050;
  width: 150px;
  margin-left: -0.3em;
}

div.shoutbox div.shouts span.message {
  overflow-wrap: anywhere;
  color: #fff;
}

div.shoutbox div.shouts img {
  height: 1.5em;
}

div.shoutbox div.shouts span.timestamp {
  color: #32cd32;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  div.shoutbox div.inputs input.nick {
    margin-left: auto;
    margin-right: auto;
    margin-top: -0.3em;
    width: 98vw;
  }
  div.shoutbox div.inputs input.message {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.3em;
    width: 98vw;
  }
  div.shoutbox div.inputs button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5em;
    height: 2em;
    width: 98vw;
  }
  div.shoutbox div.shouts {
    margin-top: 0.8em;
    width: 98gvw;
  }
}
